import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/news.tsx";
export const pageTitle = "Armeria Newsletter vol. 3";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "armeria-newsletter-vol-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#armeria-newsletter-vol-3",
        "aria-label": "armeria newsletter vol 3 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Armeria Newsletter vol. 3`}</h1>
    <p {...{
      "className": "date"
    }}>{`29th October 2021`}</p>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-devs"
        }}>{`From the devs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-community"
        }}>{`From the community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#we-need-your-comments"
        }}>{`We need your comments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#good-first-issues"
        }}>{`Good first issues`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#stay-tuned"
        }}>{`Stay tuned`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#thank-you"
        }}>{`Thank you!`}</a></li>
    </ul>
    <video {...{
      "className": "hideOnReducedMotion",
      "src": "/bf04f0ae6df78db5375887fb6f8e2f78/armeria.m4v",
      "preload": "none",
      "autoPlay": true,
      "muted": true,
      "loop": true,
      "style": {
        "width": "282px",
        "height": "112px"
      }
    }}>{`
  `}<img parentName="video" {...{
        "src": "/2cbd433cd318026a1c3017f022dbc263/armeria.gif",
        "loading": "lazy",
        "width": 282,
        "height": 112
      }}></img>
    </video>
    <h2 {...{
      "id": "from-the-devs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-devs",
        "aria-label": "from the devs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the devs`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`👂 Armeria team now hosts `}<a parentName="li" {...{
            "href": "https://armeria.dev/community/office-hours/"
          }}>{`bi-weekly virtual office hours`}</a>{`.`}
          <ul parentName="li">
            <li parentName="ul">{`Please feel free to join in and share your thoughts!`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`✨ We released 15 releases since our last newsletter with a bunch of new exciting features!`}
          <ul parentName="li">
            <li parentName="ul">{`HTTP/JSON-to-gRPC transcoding like `}<a parentName="li" {...{
                "href": "https://cloud.google.com/endpoints/docs/grpc/transcoding"
              }}>{`Google does`}</a>{`,
`}<em parentName="li">{`without`}</em>{` any API gateway server`}</li>
            <li parentName="ul">{`GraphQL support with `}<a parentName="li" {...{
                "href": "https://www.graphql-java.com/"
              }}>{`GraphQL Java`}</a>{` and `}<a parentName="li" {...{
                "href": "https://sangria-graphql.github.io/"
              }}>{`Sangria`}</a>{`.`}</li>
            <li parentName="ul">{`Immutable `}<a parentName="li" {...{
                "href": "type://Cookie:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookie.html"
              }}>{`type://Cookie`}</a>{` API designed with usability in mind`}</li>
            <li parentName="ul">{`Fully asynchronous OAuth 2.0 module`}</li>
            <li parentName="ul">{`Reactive multipart streaming API`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
              }}>{`type://WebClient`}</a>{` can now follow redirects with highly customizable redirect rules.`}</li>
            <li parentName="ul">{`Convenient stream transformation APIs such as `}<inlineCode parentName="li">{`map`}</inlineCode>{`, `}<inlineCode parentName="li">{`concat`}</inlineCode>{`, `}<inlineCode parentName="li">{`recover`}</inlineCode>{` and `}<inlineCode parentName="li">{`collect`}</inlineCode></li>
            <li parentName="ul">{`Dynamic server reconfiguration with `}<a parentName="li" {...{
                "href": "type://Server#reconfigure()"
              }}>{`type://Server#reconfigure()`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://armeria.dev/docs/advanced-scala"
              }}>{`Scala integration`}</a></li>
            <li parentName="ul">{`Other numerous API usability improvements`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "from-the-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-community",
        "aria-label": "from the community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the community`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🎓 Did you know you can build a REST API with Armeria?`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://armeria.dev/tutorials/rest/blog"
              }}>{`Try this guided tutorial`}</a>{` by `}<a parentName="li" {...{
                "href": "https://github.com/freevie"
              }}><strong parentName="a">{`@freevie`}</strong></a>{` to learn how to write a REST endpoint with Armeria.`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`📈 `}<a parentName="li" {...{
            "href": "https://engineering.linecorp.com/en/blog/monitoring-prometheus-metrics-from-armeria/"
          }}>{`Monitoring Prometheus metrics from Armeria`}</a></li>
        <li parentName="ul">{`🎨 `}<a parentName="li" {...{
            "href": "https://engineering.linecorp.com/en/blog/customizing-armeria-metrics/"
          }}>{`Customizing Armeria metrics`}</a>
          <ul parentName="li">
            <li parentName="ul">{`In this 2-part article, `}<a parentName="li" {...{
                "href": "https://github.com/jupiny"
              }}><strong parentName="a">{`@jupiny`}</strong></a>{` shows you how you can record the detailed metrics and customize them
in your Armeria service. `}</li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`💉 Interested in service-level automatic fault injection testing?`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.youtube.com/watch?v=BhZLHpxQ7mI"
              }}>{`Check out this demo video`}</a>{` by `}<a parentName="li" {...{
                "href": "https://github.com/cmeiklejohn"
              }}><strong parentName="a">{`@cmeiklejohn`}</strong></a>{` that shows
building an application with Armeria and then using `}<a parentName="li" {...{
                "href": "https://www.youtube.com/watch?v=pyYh-vNspAI"
              }}>{`Filibuster (paper accepted to ACM SoCC '21)`}</a>{`
for automatically testing the failure scenarios caused by remote services and dependencies.`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🖥 Looking for a virtual tech conference?`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://linedevday.linecorp.com/2021/en/sessions/31/"
              }}>{`Register now for the talk`}</a>{` by `}<a parentName="li" {...{
                "href": "https://github.com/cnabro"
              }}><strong parentName="a">{`@cnabro`}</strong></a>{` about
his experience building a large scale gRPC and GraphQL service with Armeria on Nov 11th.`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "we-need-your-comments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#we-need-your-comments",
        "aria-label": "we need your comments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We need your comments`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🤔 Your opinion means a lot to us. Please let us know what you think about these proposals:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/3598"
              }}>{`#3598`}</a>{` Reconsider the restartability of `}<a parentName="li" {...{
                "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
              }}>{`type://Server`}</a>{`.`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/3700"
              }}>{`#3700`}</a>{` Should `}<a parentName="li" {...{
                "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
              }}>{`type://Endpoint`}</a>{` have optional `}<a parentName="li" {...{
                "href": "type://SessionProtocol:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/SessionProtocol.html"
              }}>{`type://SessionProtocol`}</a>{` and `}<a parentName="li" {...{
                "href": "type://SerializationFormat:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/SerializationFormat.html"
              }}>{`type://SerializationFormat`}</a>{`?`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/3820"
              }}>{`#3820`}</a>{` Nullable or exception-throwing getters in `}<a parentName="li" {...{
                "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
              }}>{`type://RequestHeaders`}</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "good-first-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#good-first-issues",
        "aria-label": "good first issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Good first issues`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🔰 Want to contribute but not sure where to start from? Try one of these:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/2353"
              }}>{`#2353`}</a>{` Add a gRPC reverse proxy example.`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/3788"
              }}>{`#3788`}</a>{` Deprecate `}<a parentName="li" {...{
                "href": "type://Cookie#builder(String,String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Cookie.html#builder(java.lang.String,java.lang.String)"
              }}>{`type://Cookie#builder(String,String)`}</a>{` and introduce a method with secure defaults.`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h2>
    <p>{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}</p>
    <div style={{
      "maxWidth": "320px",
      "marginTop": "0.5rem"
    }}>
  <Mailchimp mdxType="Mailchimp" />
    </div>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you!`}</h2>
    <ThankYou message={() => <p>Since our last newsletter, we received awesome contributions from the following contributors:</p>} usernames={['alexc-db', 'amitvc', 'andrey-tpt', 'anuraaga', 'ateirney', 'berry120', 'dgahn', 'eisig', 'eugene70', 'fantayeneh', 'fclemonschool', 'freevie', 'ghkim3221', 'haithamgabr', 'heka1024', 'heowc', 'hexoul', 'hirakida', 'hyangtack', 'icepeppermint', 'ikhoon', 'imasahiro', 'inch772', 'jasper-vandemalle', 'jrhee17', 'JunoJunho', 'jupiny', 'KarboniteKream', 'karellen-kim', 'kezhenxu94', 'kojilin', 'ks-yim', 'm50d', 'mauhiz', 'max904-github', 'minwoox', 'mynameisjwlee', 'nirvanarsc', 'octo47', 'okue', 'policeman-kh', 'probepark', 'richieyan', 'selectAll', 'serajin00', 'sfc-gh-pbennes', 'sunken-kang', 'szeiger', 'TheWeaVer', 'tobias-', 'trustin', 'vivekkothari', 'wasifaleemdd', 'Waynefn']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      